import { SupportedChainId } from 'constants/chains'

// /**
//  * Map of supported chains to USDC contract addresses
//  */
// export const CHAIN_IDS_TO_USDC_ADDRESSES = {
//   [SupportedChainId.ETH_SEPOLIA]: '0x1c7d4b196cb0c7b01d743fbc6116a902379c7238',

//   [SupportedChainId.AVAX_FUJI]: '0x5425890298aed601595a70AB815c96711a31Bc65',

//   [SupportedChainId.ARB_SEPOLIA]: '0x75faf114eafb1bdbe2f0316df893fd58ce46aa4d',

//   [SupportedChainId.BASE_SEPOLIA]: '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
// }

/**
 * Map of supported chains to USDC contract addresses
 */
export const CHAIN_IDS_TO_USDC_ADDRESSES = {
  [SupportedChainId.ETH]: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',

  [SupportedChainId.AVAX]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',

  [SupportedChainId.ARB]: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',

  [SupportedChainId.BASE]: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
}

/**
 * Map of supported chains to Token Messenger contract addresses
 */
export const CHAIN_IDS_TO_TOKEN_MESSENGER_ADDRESSES = {
  [SupportedChainId.ETH]: '	0xbd3fa81b58ba92a82136038b25adec7066af3155',

  [SupportedChainId.AVAX]: '0x6b25532e1060ce10cc3b0a99e5683b91bfde6982',

  [SupportedChainId.ARB]: '0x19330d10D9Cc8751218eaf51E8885D058642E08A',

  [SupportedChainId.BASE]: '0x1682Ae6375C4E4A97e4B583BC394c861A46D8962',
}

/**
 * Map of supported chains to Message Transmitter contract addresses
 */
export const CHAIN_IDS_TO_MESSAGE_TRANSMITTER_ADDRESSES = {
  [SupportedChainId.ETH]: '	0x0a992d191deec32afe36203ad87d7d289a738f81',

  [SupportedChainId.AVAX]: '0x8186359af5f57fbb40c6b14a588d2a59c0c29880',

  [SupportedChainId.ARB]: '0xC30362313FBBA5cf9163F0bb16a0e01f01A896ca',

  [SupportedChainId.BASE]: '0xAD09780d193884d503182aD4588450C416D6F9D4',
}
